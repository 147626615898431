import React from "react";
//import ColectivoImg from "../../images/intro-slider/colectivo-img.jpg";

const CollectivoComp = props => {
  return (
    <React.Fragment>
      <div className="flexy">
        <div>
          <div className="text-container-center less-bottom-margin">
            {props.colectivoTextContent.map((textContentItem, idx) => {
              return <p key={idx}>{textContentItem}</p>;
            })}
            {/*
            <p>
              Sein erstes eigenes musikalisches Konzept verwirklichte Leandro in
              2008 mit seiner Band Saint-HillColectivo, die unterschiedliche
              Genres und Stilrichtungen in Soul und Funk eindrucksvoll
              kombiniert. Groovige Beats des Funks verschmelzen mit
              afrokaribischen Rhythmen und bilden eine dynamische Grundlage für
              das Gefühl des Soul, die Improvisation des Jazz und vor allem für
              die ausdrucksstarken Texte.
            </p>

            <p>
              Das Saint-Hill Colectivo steht für eine Vielfalt von
              Musikrichtungen. Trotzdem scheinen durchgängig Leandros kubanische
              und afrokaribische Wurzeln durch und die Inspiration durch große
              Musiker wie The Brothers Johnson und Maceo Parker. Hier ist nicht
              nur Platz für feine Soli und kraftvolle Soneos, sondern auch für
              Gastauftritte von internationalen Größen wie Omar Sosa, Arturo
              Martinez, Nené Vasquez und Tony Martinez.
            </p>

            <p>
              Saint-Hill Colectivo steht daher für eine wirkungsvolle Symbiose
              aus Funk/Soul und karibischer Musik, mit einer gehörigen Prise
              kontemporärem Jazz and Blues. Das Septett überzeugt aber nicht nur
              im Studio, sondern vor allem aauch uf der Bühne. Die charmante
              Performance in Verbindung mit ausgefeilter Musik begeistert jedes
              Publikum.
            </p>
            */}
          </div>
        </div>
      </div>
      <div className="flexy less-top-padding no-margin">
        <div>
          <div className="img-container">
            <img
              src={props.colectivoImage}
              title="Leandro Saint-Hill Colectivo"
              alt="Leandro Saint-Hill Colectivo"
            />
          </div>
        </div>
      </div>
      <div className="divider">
        <hr />
      </div>
    </React.Fragment>
  );
};

export default CollectivoComp;
