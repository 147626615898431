import React from 'react';
import './SocialSidebar.css';
import {
  FaFacebookSquare,
  //FaAmazon,
  //FaYoutube,
  //FaItunes,
  FaSpotify
  //FaInstagram
} from 'react-icons/fa';

const SocialSidebar = () => {
  return (
    <div className="social-sidebar">
      <ul>
        <li>
          <a
            href="https://www.facebook.com/Leandro-Saint-hill-Montejo-1784251618555879/"
            title="Facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookSquare size="1.4em" />
          </a>
        </li>
        {/*
        <li>
          <a
            href="https://www.amazon.de/"
            title="Amazone"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaAmazon size="1.4em" />
          </a>
        </li>

        <li>
          <a
            href="https://www.youtube.com/"
            title="Youtube"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube size="1.4em" />
          </a>
        </li>

        <li>
          <a
            href="https://www.apple.com/music/"
            title="iTunes"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaItunes size="1.4em" />
          </a>
        </li>
*/}
        <li>
          <a
            href="https://open.spotify.com/artist/12YdVtFtnjCdNjLC9NVGz1?si=5pIcI9EhTYuuCXfFiCW5yA"
            title="Spotify"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaSpotify size="1.4em" />
          </a>
        </li>
        {/* <li>
          <a
            href="https://www.instagram.com/?hl=de"
            title="Instagram"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram size="1.4em" />
          </a>
        </li> */}
      </ul>
    </div>
  );
};

export default SocialSidebar;
