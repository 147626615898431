import React from "react";
import "./IntroSlider.css";

const IntroSlider = props => {
  const { introImages } = props;
  return (
    <React.Fragment>
      <div className="slideshow">
        {introImages.map(image => (
          <div
            key={image}
            className="slideshow-image"
            style={{
              backgroundImage: "url(" + image + ")"
            }}
          />
        ))}
      </div>
      <div className="slider-overlay" />
      <div className="shape-home" />
    </React.Fragment>
  );
};

export default IntroSlider;
