import React from "react";
import "./ContactComp.css";

const ContactComp = props => {
  const {
    contactBookingHeading,
    contactBookingName,
    contactBookingCompany,
    contactBookingPhone,
    contactBookingEmail,
    contactLabelHeading,
    contactLabelName,
    contactLabelCompany,
    contactLabelPhone,
    contactLabelEmail,
    contactDownloadHeading,
    contactDownloadFiles
  } = props;

  const renderPresseFiles = files => {
    if (files && files.length) {
      return files.map((item, idx) => {
        return (
          <React.Fragment key={idx}>
            <a href={item.url} alt={item.name} download>
              {item.name}
            </a>
            <br />
          </React.Fragment>
        );
      });
    }
    return null;
  };

  return (
    <div className="text-container-center no-margin">
      <h3>{contactBookingHeading}</h3>
      <p className="no-margin more-bottom-margin">
        {contactBookingName}
        <br />
        {contactBookingCompany}
        <br />
        {contactBookingPhone}
        <br />
        {contactBookingEmail}
        <br />
      </p>

      <h3>{contactLabelHeading}</h3>
      <p className="no-margin more-bottom-margin">
        {contactLabelName}
        <br />
        {contactLabelCompany}
        <br />
        {contactLabelPhone}
        <br />
        {contactLabelEmail}
        <br />
      </p>

      <h3>{contactDownloadHeading}</h3>
      <p className="no-margin">{renderPresseFiles(contactDownloadFiles)}</p>
    </div>
  );
};

export default ContactComp;
