import React from "react";
import ReactDOM from "react-dom";
import smoothscroll from "smoothscroll-polyfill";
import "./index.css";
import "./simple-grid.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

function cssPropertyValueSupported(prop, value) {
  var d = document.createElement("div");
  d.style[prop] = value;
  return d.style[prop] === value;
}

const sSisSupported = cssPropertyValueSupported("scroll-behavior", "smooth");
if (!sSisSupported) {
  smoothscroll.polyfill();
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
