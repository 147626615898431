import React from "react";
import "./ParallaxImage.css";

const ParallaxImage = props => {
  const { bgImage } = props;

  return (
    <div className="parallax-container">
      <div
        className="parallax-before"
        style={{
          backgroundImage: `url(${bgImage})`
        }}
      />
      <div className="parallax-overlay" />
    </div>
  );
};

export default ParallaxImage;
