import React from "react";
import "./QuartetComp.css";
import { FaPlayCircle } from "react-icons/fa";

const QuartetComp = (props) => {
  return (
    <React.Fragment>
      <div className="flexy">
        <div>
          <div className="text-container-center less-bottom-margin">
            {props.quartetTextContentQuartet.map(
              (quartetTextContentItem, idx) => {
                return <p key={idx}>{quartetTextContentItem}</p>;
              }
            )}

            <div id="new-release">
              <div className="container">
                <img
                  src={props.quartetAlbumImage}
                  alt="Cadenica - Leandro Saint-Hill"
                />
                <h3>{props.quartetAlbumTitle}</h3>

                <a
                  className="intro-btn"
                  href={props.quartetAlbumLink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FaPlayCircle className="play-icon" />
                </a>
              </div>
            </div>

            {props.quartetTextContentAlbum.map(
              (quartetTextContentItem, idx) => {
                return <p key={idx}>{quartetTextContentItem}</p>;
              }
            )}

            {/* <h3 className="more-top-margin">{props.quartetMembersHeading}</h3> */}
          </div>
        </div>
      </div>
      <div className="flexy less-top-padding more-bottom-margin">
        {props.quartetMembers.map((member, idx) => {
          const { name, img, profession } = member;
          return (
            <div key={idx}>
              <div className="img-container">
                <img src={img} title={name} alt={name} />
                <span>
                  <h4>{name}</h4>
                  <h5>{profession}</h5>
                </span>
              </div>
            </div>
          );
        })}
      </div>

      <div className="text-container-center no-margin">
        <h3>{props.quartetGastMusikerHeading}</h3>
        <p className="no-margin">
          {props.quartetGastMusiker.map((gastMusiker, idx) => {
            return (
              <small key={idx}>
                {gastMusiker.instrument} : <b>{gastMusiker.name}</b>{" "}
                {gastMusiker.from} |{" "}
              </small>
            );
          })}
        </p>
      </div>
      <div className="divider">
        <hr />
      </div>
    </React.Fragment>
  );
};

export default QuartetComp;
