import React from "react";
import "./section-title.css";

const SectionTitle = props => {
  const { mainTxt, subTxt } = props;

  return (
    <React.Fragment>
      <h2 className="section-title">{mainTxt}</h2>
      <h3 className="sub-title">{subTxt}</h3>
    </React.Fragment>
  );
};

export default SectionTitle;
