import React, { Component } from 'react';
import Brand from '../brand/Brand';
import CollapsableMenu from '../menu/CollapsableMenu';
import './Header.css';

export default class Header extends Component {
  onBrandClick = (evt) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  render() {
    const { isHeaderFade, isMenuDark } = this.props;
    return (
      <header className={isHeaderFade ? 'header-background-fade' : ''}>
        <Brand brand="lsh" onClick={this.onBrandClick} />
        <nav>
          <CollapsableMenu
            isMenuDark={isMenuDark}
            menuItems={[
              'about',
              'quartet',
              'colectivo',
              'tourdates',
              'media',
              'reviews',
              'contact',
            ]}
          />
        </nav>
      </header>
    );
  }
}
