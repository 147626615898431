import React from 'react';
import { FaAngleDown } from 'react-icons/fa';
import './IntroHero.css';

const IntroHero = props => {
  const onArrowClick = evt => {
    const targetElement = document.getElementById('about');
    const headerOffset = window.innerWidth >= 1000 ? 96 : 64;
    let elPos = targetElement.getBoundingClientRect().top;
    let offsetPos = elPos - headerOffset;

    window.scrollTo({
      top: offsetPos,
      behavior: 'smooth'
    });
  };

  const handleAlbumClick = event => {
    event.preventDefault();
    const { href } = event.currentTarget;
    const targetElementId = href.substring(href.lastIndexOf('#') + 1);
    const targetElement = document.getElementById(targetElementId);
    const headerOffset = window.innerWidth >= 1000 ? 96 + 45 : 64 + 45;
    const elPos = targetElement.getBoundingClientRect().top + window.scrollY;
    const offsetPos = elPos - headerOffset;
    window.scroll({
      top: offsetPos,
      behavior: 'smooth'
    });
  };

  return (
    <div className='intro-hero'>
      <h1>{props.homeHeading}</h1>
      <p>{props.homeSubHeading}</p>
      <div className='new-album-release'>
        <a
          className='cover-img-link'
          href='#new-release'
          onClick={handleAlbumClick}
          style={{ display: props.isCoverActive ? 'block' : 'block' }}
        >
          <div className='ribbon'>
            <span>New Release</span>
          </div>
          <img src={props.homeAlbumCover} alt='New Release' />
        </a>
        <div
          className='alt-text'
          style={{
            display: props.altText.length ? 'block' : 'none',
            color: 'white',
            fontSize: '120%',
            margin: '12px 0 0 0',
            padding: '0 12px',
            textAlign: 'center'
          }}
          dangerouslySetInnerHTML={{ __html: atob(props.altText) }}
        />
      </div>
      <div className='arrow bounce noSelect' onClick={onArrowClick}>
        <FaAngleDown />
      </div>
    </div>
  );
};

export default IntroHero;
