import React from "react";
import "./AboutComp.css";

const AboutComp = props => {
  const { aboutImage, aboutTextContent } = props;

  return (
    <React.Fragment>
      <div>
        <div className="img-container-left">
          <img src={aboutImage} alt="Leandro Saint-Hill Biographie" />
        </div>
      </div>
      <div>
        <div className="text-container-right">
          {aboutTextContent.map((textContentItem, idx) => {
            return (
              <div key={idx}>
                <h3>{textContentItem.heading}</h3>
                {textContentItem.paragraphs.map((paragraphItem, idx) => {
                  return <p key={idx}>{paragraphItem}</p>;
                })}
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AboutComp;
