import React, { Component } from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './App.css';
import Header from './components/header/Header';
import Spinner from './components/spinner/Spinner';
import IntroSlider from './components/intro-slider/IntroSlider';
import SocialSidebar from './components/social-sidebar/SocialSidebar';
import IntroHero from './components/intro-hero/IntroHero';
import SectionTitle from './section-title/SectionTitle';
import AboutComp from './components/about-comp/AboutComp';
import ParallaxImage from './components/parallax-image/ParallaxImage';
import QuartetComp from './components/quartet-comp/QuartetComp';
import CollectivoComp from './components/collectivo-comp/CollectivoComp';
import {
  IoIosMegaphone,
  IoIosBowtie,
  IoIosHeart,
  IoMdAppstore,
} from 'react-icons/io';

import './mediaqueries.css';
import ContactComp from './components/contact-comp/ContactComp';

class App extends Component {
  state = {
    /* home section -------------------------------
    ---------------------------------------------*/
    sliderImages: [],
    homeHeading: '',
    homeSubHeading: '',
    homeAlbumCover: '',
    isCoverActive: '',
    altText: '',
    /* about section ------------------------------
    ---------------------------------------------*/
    aboutHeading: '',
    aboutSubHeading: '',
    aboutImage: '',
    aboutParaImg: '',
    aboutTextContent: [],
    /* quartet section ----------------------------
    ---------------------------------------------*/
    quartetHeading: '',
    quartetSubHeading: '',
    quartetAlbumTitle: '',
    quartetalbumLink: '',
    quartetTextContentQuartet: [],
    quartetTextContentAlbum: [],
    quartetMembersHeading: '',
    quartetMembers: [],
    quartetGastMusikerHeading: '',
    quartetGastMusiker: [],
    /* quartet section ----------------------------
    ---------------------------------------------*/
    colectivoHeading: '',
    colectivoSubHeading: '',
    colectivoImage: '',
    colectivoTextContent: [],
    /* tourdates section -------------------------
    ---------------------------------------------*/
    tourdatesHeading: '',
    tourdatesSubHeading: '',
    tourdatesParaImg: '',
    tourdatesItems: [],
    /* media section -------------------------
    ---------------------------------------------*/
    mediaHeading: '',
    mediaSubHeading: '',
    mediaItems: [],
    /* reviews section -------------------------
    ---------------------------------------------*/
    reviewHeading: '',
    reviewSubHeading: '',
    reviewItems: [],
    /* contact section -------------------------
    ---------------------------------------------*/
    contactHeading: '',
    contactSubHeading: '',
    contactBookingHeading: '',
    contactBookingName: '',
    contactBookingCompany: '',
    contactBookingPhone: '',
    contactBookingEmail: '',
    contactLabelHeading: '',
    contactLabelName: '',
    contactLabelCompany: '',
    contactLabelPhone: '',
    contactLabelEmail: '',
    contactDownloadHeading: '',
    contactDownloadFiles: [],
    /* utils section ----------------------------
    ---------------------------------------------*/
    isLoading: true,
    isHeaderFade: false,
    isHeaderFixed: false,
    isMenuDark: false,
    error: null,
  };

  componentDidMount() {
    //fetch("/leandro/index.php")
    fetch('cnt.json')
      .then((res) => res.json())
      .then((data) => {
        // home section data
        const {
          sliderImages,
          heading: homeHeading,
          subHeading: homeSubHeading,
          albumCover: homeAlbumCover,
          isCoverActive,
          altText,
        } = data.section.home;

        // about section data
        const {
          heading: aboutHeading,
          subHeading: aboutSubHeading,
          aboutImage,
          aboutParaImg,
          textContent: aboutTextContent,
        } = data.section.about;

        // quartet section data
        const {
          heading: quartetHeading,
          subHeading: quartetSubHeading,
          albumTitle: quartetAlbumTitle,
          albumLink: quartetAlbumLink,
          textContentQuartet: quartetTextContentQuartet,
          textContentAlbum: quartetTextContentAlbum,
          quartetMembersHeading,
          quartetMembers,
          quartetGastMusikerHeading,
          quartetGastMusiker,
        } = data.section.quartet;

        // colectivo section data
        const {
          heading: colectivoHeading,
          subHeading: colectivoSubHeading,
          colectivoImage,
          textContent: colectivoTextContent,
        } = data.section.colectivo;

        // tourdates section data
        const {
          heading: tourdatesHeading,
          subHeading: tourdatesSubHeading,
          tourdatesParaImg,
          events: tourdatesItems,
        } = data.section.tourdates;

        // media section data
        const {
          heading: mediaHeading,
          subHeading: mediaSubHeading,
          youtubeVideos: mediaItems,
        } = data.section.media;

        // reviews section data
        const {
          heading: reviewHeading,
          subHeading: reviewSubHeading,
          reviewLinks: reviewItems,
        } = data.section.reviews;

        // contact section data
        const {
          heading: contactHeading,
          subHeading: contactSubHeading,
          booking: {
            heading: contactBookingHeading,
            name: contactBookingName,
            company: contactBookingCompany,
            phone: contactBookingPhone,
            email: contactBookingEmail,
          },
          label: {
            heading: contactLabelHeading,
            name: contactLabelName,
            company: contactLabelCompany,
            phone: contactLabelPhone,
            email: contactLabelEmail,
          },
          downloads: {
            heading: contactDownloadHeading,
            files: contactDownloadFiles,
          },
        } = data.section.contact;

        // hide preloader as soon as the first slider img has been loaded
        const [firstImg] = sliderImages;
        const img = new Image();
        img.onload = () => {
          this.setState({
            isLoading: false,
            /* set home section data to state -------------------------------
            ---------------------------------------------------------------*/
            sliderImages: sliderImages,
            homeHeading: homeHeading,
            homeSubHeading: homeSubHeading,
            homeAlbumCover: homeAlbumCover,
            isCoverActive: isCoverActive,
            altText: altText,
            /* set about section data to state -------------------------------
            ----------------------------------------------------------------*/
            aboutHeading: aboutHeading,
            aboutSubHeading: aboutSubHeading,
            aboutImage: aboutImage,
            aboutParaImg: aboutParaImg,
            aboutTextContent: aboutTextContent,
            /* set quartet section data to state -----------------------------
          ----------------------------------------------------------------*/
            quartetHeading: quartetHeading,
            quartetSubHeading: quartetSubHeading,
            quartetAlbumTitle: quartetAlbumTitle,
            quartetAlbumLink: quartetAlbumLink,
            quartetTextContentQuartet: quartetTextContentQuartet,
            quartetTextContentAlbum: quartetTextContentAlbum,
            quartetMembersHeading: quartetMembersHeading,
            quartetMembers: quartetMembers,
            quartetGastMusikerHeading: quartetGastMusikerHeading,
            quartetGastMusiker: quartetGastMusiker,
            /* set colectivo section data to state ---------------------------
            ----------------------------------------------------------------*/
            colectivoHeading: colectivoHeading,
            colectivoSubHeading: colectivoSubHeading,
            colectivoImage: colectivoImage,
            colectivoTextContent: colectivoTextContent,
            /* set tourdates section data to state ---------------------------
            ----------------------------------------------------------------*/
            tourdatesHeading: tourdatesHeading,
            tourdatesSubHeading: tourdatesSubHeading,
            tourdatesParaImg: tourdatesParaImg,
            tourdatesItems: tourdatesItems,
            /* set media section data to state ---------------------------
            ----------------------------------------------------------------*/
            mediaHeading: mediaHeading,
            mediaSubHeading: mediaSubHeading,
            mediaItems: mediaItems.reverse(),
            /* set media section data to state ---------------------------
            ----------------------------------------------------------------*/
            reviewHeading: reviewHeading,
            reviewSubHeading: reviewSubHeading,
            reviewItems: reviewItems.reverse(),
            /* set contact section data to state ---------------------------
            ----------------------------------------------------------------*/
            contactHeading: contactHeading,
            contactSubHeading: contactSubHeading,
            contactBookingHeading: contactBookingHeading,
            contactBookingName: contactBookingName,
            contactBookingCompany: contactBookingCompany,
            contactBookingPhone: contactBookingPhone,
            contactBookingEmail: contactBookingEmail,
            contactLabelHeading: contactLabelHeading,
            contactLabelName: contactLabelName,
            contactLabelCompany: contactLabelCompany,
            contactLabelPhone: contactLabelPhone,
            contactLabelEmail: contactLabelEmail,
            contactDownloadHeading: contactDownloadHeading,
            contactDownloadFiles: contactDownloadFiles,
          });
        };
        img.src = firstImg;
      })
      .catch((error) => console.log(error));

    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = (evt) => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const aboutTopPos = document.getElementById('about').getBoundingClientRect()
      .top;
    if (scrollTop > aboutTopPos) {
      this.setState({
        isHeaderFade: true,
        isMenuDark: true,
      });
    } else {
      this.setState({
        isHeaderFade: false,
        isMenuDark: false,
      });
    }
  };

  onEventClick = (evt) => {
    const url = evt.currentTarget.dataset.targetUrl;
    let win = window.open(url, '_blank');
    win.focus();
  };

  randomTourdateIcon(idx) {
    let icons = [<IoIosBowtie />, <IoIosHeart />, <IoIosMegaphone />];
    return icons[idx % 3];
  }

  buildTourdates(tourdatesItems) {
    const dateRender = (date, link) => {
      return (
        <div>
          <p className="date">{date}</p>
          {link.length ? (
            <p>
              <button
                onClick={this.onEventClick}
                className="cards-btn"
                data-target-url={link}
              >
                <IoMdAppstore />
                Mehr...
              </button>
            </p>
          ) : null}
        </div>
      );
    };

    if (tourdatesItems && tourdatesItems.length) {
      return tourdatesItems.map((item, idx) => {
        return (
          <VerticalTimelineElement
            key={idx}
            className="vertical-timeline-element--work"
            //date={item.date}
            date={dateRender(item.date, item.link)}
            iconStyle={{ background: 'var(--exo)', color: '#fff' }}
            icon={this.randomTourdateIcon(idx)}
          >
            <h3 className="vertical-timeline-element-title">{item.location}</h3>
            <h4 className="vertical-timeline-element-subtitle">{item.act}</h4>
          </VerticalTimelineElement>
        );
      });
    } else {
      return (
        <h3
          style={{
            position: 'absolute',
            top: '120px',
            textAlign: 'center',
            width: '100%',
          }}
        >
          Coming soon.
        </h3>
      );
    }
  }

  render() {
    const {
      sliderImages,
      // home data
      homeHeading,
      homeSubHeading,
      homeAlbumCover,
      isCoverActive,
      altText,
      // about data
      aboutHeading,
      aboutSubHeading,
      aboutImage,
      aboutParaImg,
      aboutTextContent,
      // quartet data
      quartetHeading,
      quartetSubHeading,
      quartetAlbumTitle,
      quartetAlbumLink,
      quartetTextContentQuartet,
      quartetTextContentAlbum,
      quartetMembersHeading,
      quartetMembers,
      quartetGastMusikerHeading,
      quartetGastMusiker,
      // colectivo data
      colectivoHeading,
      colectivoSubHeading,
      colectivoImage,
      colectivoTextContent,
      // tourdates data
      tourdatesHeading,
      tourdatesSubHeading,
      tourdatesParaImg,
      tourdatesItems,
      // media data
      mediaHeading,
      mediaSubHeading,
      mediaItems,
      // review data
      reviewHeading,
      reviewSubHeading,
      reviewItems,
      // contact data
      contactHeading,
      contactSubHeading,
      contactBookingHeading,
      contactBookingName,
      contactBookingCompany,
      contactBookingPhone,
      contactBookingEmail,
      contactLabelHeading,
      contactLabelName,
      contactLabelCompany,
      contactLabelPhone,
      contactLabelEmail,
      contactDownloadHeading,
      contactDownloadFiles,
      // utils data
      isLoading,
      isHeaderFade,
      isMenuDark,
    } = this.state;

    return (
      <React.Fragment>
        <Spinner isVisible={isLoading} />
        <Header isHeaderFade={isHeaderFade} isMenuDark={isMenuDark} />

        <section id="home">
          <IntroSlider introImages={sliderImages} />
          <IntroHero
            homeHeading={homeHeading}
            homeSubHeading={homeSubHeading}
            homeAlbumCover={homeAlbumCover}
            isCoverActive={isCoverActive}
            altText={altText}
          />
          <SocialSidebar />
        </section>

        <section id="about" className="section-center">
          <SectionTitle mainTxt={aboutHeading} subTxt={aboutSubHeading} />
          <div className="flexy">
            <AboutComp
              aboutImage={aboutImage}
              aboutTextContent={aboutTextContent}
            />
          </div>
        </section>

        <ParallaxImage bgImage={aboutParaImg} />

        <section id="quartet" className="section-center">
          <SectionTitle mainTxt={quartetHeading} subTxt={quartetSubHeading} />
          <QuartetComp
            quartetTextContentQuartet={quartetTextContentQuartet}
            quartetAlbumTitle={quartetAlbumTitle}
            quartetAlbumImage={homeAlbumCover}
            quartetAlbumLink={quartetAlbumLink}
            quartetTextContentAlbum={quartetTextContentAlbum}
            quartetMembersHeading={quartetMembersHeading}
            quartetMembers={quartetMembers}
            quartetGastMusikerHeading={quartetGastMusikerHeading}
            quartetGastMusiker={quartetGastMusiker}
          />
        </section>

        <section id="colectivo" className="section-center">
          <SectionTitle
            mainTxt={colectivoHeading}
            subTxt={colectivoSubHeading}
          />
          <CollectivoComp
            colectivoImage={colectivoImage}
            colectivoTextContent={colectivoTextContent}
          />
        </section>

        <section id="tourdates" className="section-center">
          <SectionTitle
            mainTxt={tourdatesHeading}
            subTxt={tourdatesSubHeading}
          />
          <VerticalTimeline>
            {this.buildTourdates(tourdatesItems)}
          </VerticalTimeline>
        </section>

        <section id="media" className="section-center">
          <SectionTitle mainTxt={mediaHeading} subTxt={mediaSubHeading} />
          <div className="media-container" style={{ paddingTop: '64px' }}>
            {mediaItems.map((vid) => {
              var urlSearchParams = new URLSearchParams(
                vid.url.slice(vid.url.indexOf('?'))
              );
              var params = Object.fromEntries(urlSearchParams.entries());
              return (
                <div className="video-container" key={params['v']}>
                  <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube-nocookie.com/embed/${
                      params['v']
                    }`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              );
            })}
          </div>
        </section>

        <section
          id="reviews"
          className="section-center"
          style={{
            height: 'auto',
            minHeight: 'initial',
            padding: '32px 0 64px',
          }}
        >
          <SectionTitle mainTxt={reviewHeading} subTxt={reviewSubHeading} />

          <div className="review-container" style={{ paddingTop: '48px' }}>
            {reviewItems.map((item, idx) => {
              return (
                <p className="review-links" key={idx}>
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    {item.title}
                  </a>
                </p>
              );
            })}
          </div>
        </section>

        <ParallaxImage bgImage={tourdatesParaImg} />
        <section id="contact" className="section-center">
          <SectionTitle mainTxt={contactHeading} subTxt={contactSubHeading} />
          <ContactComp
            contactBookingHeading={contactBookingHeading}
            contactBookingName={contactBookingName}
            contactBookingCompany={contactBookingCompany}
            contactBookingPhone={contactBookingPhone}
            contactBookingEmail={contactBookingEmail}
            contactLabelHeading={contactLabelHeading}
            contactLabelName={contactLabelName}
            contactLabelCompany={contactLabelCompany}
            contactLabelPhone={contactLabelPhone}
            contactLabelEmail={contactLabelEmail}
            contactDownloadHeading={contactDownloadHeading}
            contactDownloadFiles={contactDownloadFiles}
          />
        </section>
        <footer>
          <section className="section-center">
            <div className="text-container-center no-margin">
              <div className="disclaimers">
                <a href="impressum.html">Impressum</a>
                <a href="datenschutz.html">Datenschutz</a>
              </div>
            </div>
          </section>
        </footer>
      </React.Fragment>
    );
  }
}

export default App;
