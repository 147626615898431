import React, { Component } from "react";
import "./CollapsableMenu.css";
import PropTypes from "prop-types";

export default class CollapsableMenu extends Component {
  state = {
    activeMenuItem: ""
  };

  hamburgerLabel = null;

  handleMenuItemClick = event => {
    event.preventDefault();
    const { href } = event.currentTarget;
    const targetElementId = href.substring(href.lastIndexOf("#") + 1);
    console.log(targetElementId);
    const targetElement = document.getElementById(targetElementId);
    const headerOffset = window.innerWidth >= 1000 ? 96 : 64;
    /*console.log(headerOffset, "header offset");*/
    const elPos = targetElement.getBoundingClientRect().top + window.scrollY;
    /*console.log(elPos, "element position");*/
    const offsetPos = elPos - headerOffset;
    /*console.log(offsetPos, "offset position");*/

    window.scroll({
      top: offsetPos,
      behavior: "smooth"
    });

    this.hamburgerLabel.click();
  };

  componentDidMount() {
    this.hamburgerLabel = document.getElementById("menu").nextElementSibling;
  }

  render() {
    const { props } = this;
    const { isMenuDark } = props;
    return (
      <div className="collapsible-menu">
        <input type="checkbox" id="menu" />
        <label htmlFor="menu">
          <button className="hamburger hamburger--elastic" type="button">
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </label>
        <div className={isMenuDark ? "menu-content dark" : "menu-content"}>
          <ul>
            {props.menuItems.map(item => (
              <li key={item.replace(/\s/g, "-")}>
                <a
                  className="noSelect"
                  href={"#" + item.replace(/\s/g, "-")}
                  onClick={this.handleMenuItemClick}
                >
                  {item}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

CollapsableMenu.propTypes = {
  menuItems: PropTypes.array.isRequired
};
