import React from "react";
import "./Spinner.css";

const Spinner = props => {
  const { isVisible } = props;
  return (
    <div
      id="loader"
      className={
        isVisible ? "spinner-container" : "spinner-container hide-spinner"
      }
    >
      <div className="lds-ripple">
        <div />
        <div />
      </div>
    </div>
  );
};

export default Spinner;
