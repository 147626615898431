import React from "react";

const Brand = props => {
  const { brand, onClick } = props;
  return (
    <div>
      <h1 className="noSelect" onClick={onClick}>
        {brand}
      </h1>
    </div>
  );
};

export default Brand;
